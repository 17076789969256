import Cookies from 'js-cookie'

export const useGtag = () => {
  // application.html.erbでセットされたwindow.gtagをそのまま利用する
  const gtag = window.gtag

  /**
   * 読み込んでいるcookieを元に、gtagのイベントを発火させる
   * 1回だけ発火するようにするため、cookieを削除する
   */
  const gtagLoginEvent = (cookieString: string | undefined) => {
    if (cookieString === undefined) {
      return
    }

    const cookieJson = JSON.parse(cookieString) as {
      event: string
      method: string
    }

    if (cookieJson.event === 'login') {
      gtag('event', cookieJson.event, {
        method: cookieJson
      })
      // delete cookie
      Cookies.remove('ENT')
    } else if (cookieJson.event === 'signup') {
      gtag('event', cookieJson.event, {
        method: cookieJson
      })
      // delete cookie
      Cookies.remove('ENT')
    }
  }


  return {
    gtag,
    gtagLoginEvent
  }
}
