<script>
import { debounce } from 'lodash'

export default {
  data: function () {
    return {
      showPageTop: false
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.handleDisplayPageTopButton)
  },
  methods: {
    handleDisplayPageTopButton: debounce(function (e) {
      var scrollY = window.scrollY
      if (scrollY > 500) {
        this.showPageTop = true
      } else {
        this.showPageTop = false
      }
    }, 400),
    scrollToPageTop: debounce(function (e) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    })
  }
}
</script>
<template>
  <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
  >
    <a
        v-if="showPageTop"
        @click="scrollToPageTop"
        class="ml-auto block h-14 w-14">
      <img src="https://cdn.restir.com/common/re_top.png" width="35" height="35" alt="to-top" class="w-full">
    </a>
  </transition>
</template>
