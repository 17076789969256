<script setup lang="ts">
import ScrollPageTop from '@/components/app/ScrollPageTop.vue'
import { onMounted } from 'vue'
import Cookies from 'js-cookie'
import { useGtag } from '@/composables/use-gtag'

// send google analytics event if 'ENT' cookie exist.
const { gtagLoginEvent } = useGtag()
onMounted(() => {
  gtagLoginEvent(Cookies.get('ENT'))
})
</script>

<template>
  <div id="bottom-block">
    <div class="fixed bottom-4 z-[40] hidden md:left-4 md:block">
      <ScrollPageTop />
    </div>
  </div>
</template>
